export const LIBRARY_STORE_KEY = 'library-tpl';
export const BRANDKIT_SETUP_KEY = 'brandkit-setup-done';
export const WIZARD_SETUP_KEY = 'wizard-setup-done';
export const WIZARD_COLOR_STORE_KEY = 'wizard-color';
export const CAMPAIGN_SHARE_LS_KEY = 'campaign-share';

export const PROFILE_KEY_ONBOARDING_DONE = 'onboardingSectionDone';
export const PROFILE_KEY_AI_ONBOARDING_DONE = 'aiOnboardingSectionDone';

export const NO_IMAGE_SVG_URL = 'https://cdn-content.optimonk.com/shared-assets/om_picture.svg';
export const MAX_ACCEPTED_RECENTLY_COLORS = 6;
export const EXCLUDED_COMPONENTS = [
  'OmRadio',
  'OmCheckbox',
  'OmFeedback',
  'OmSurvey',
  'OmTextarea',
];
export const CAMPAIGN_EVENT_TYPES = {
  pageLoad: 'optimonk#page-ready',
  popupShow: 'optimonk#campaign-show',
  popupFill: 'optimonk#campaign-before_mark_filled',
  popupReject: 'optimonk#campaign-close',
};
export const EMBEDDED_CAMPAIGN_EVENT_TYPES = {
  pageLoad: 'optimonk#page-ready',
  popupShow: 'optimonk#embedded-campaign-page-show',
  popupFill: 'optimonk#campaign-before_mark_filled',
};

export const WIZARD_LAST_PAGE_KEY = 'wizardLastPage202404';
