const { isFreemiumPackage, isNotFreemiumPackage } = require('../helpers/planDetails');

const KEY_ENABLED_FOR_FREEMIUM = 'enabledForFreemium';
const KEY_ENABLED_FOR_NOT_FREEMIUM = 'enabledForNotFreemium';
const KEY_ENABLED_FOR_MONTHLY = 'enabledForMonthly';
const KEY_ENABLED_FOR_ANNUAL = 'enabledForAnnual';

const VALIDATORS = {
  [KEY_ENABLED_FOR_FREEMIUM](plan, coupon) {
    const { name } = plan;
    return coupon?.enabledForFreemium && isFreemiumPackage(name);
  },
  [KEY_ENABLED_FOR_NOT_FREEMIUM](plan, coupon) {
    const { name } = plan;
    return coupon?.enabledForNotFreemium && isNotFreemiumPackage(name);
  },
  [KEY_ENABLED_FOR_MONTHLY](plan, coupon) {
    const { period } = plan;
    return coupon?.enabledForMonthly && period === 1;
  },
  [KEY_ENABLED_FOR_ANNUAL](plan, coupon) {
    const { period } = plan;
    return coupon?.enabledForAnnual && period === 12;
  },
};

const VALIDATIONS = [
  [KEY_ENABLED_FOR_FREEMIUM, KEY_ENABLED_FOR_NOT_FREEMIUM],
  [KEY_ENABLED_FOR_MONTHLY, KEY_ENABLED_FOR_ANNUAL],
];

module.exports = {
  VALIDATIONS,
  VALIDATORS,
  KEYS: {
    KEY_ENABLED_FOR_FREEMIUM,
    KEY_ENABLED_FOR_NOT_FREEMIUM,
    KEY_ENABLED_FOR_MONTHLY,
    KEY_ENABLED_FOR_ANNUAL,
  },
};
