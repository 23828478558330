import * as Cookies from 'js-cookie';
import planDetails from '@om/payment/src/helpers/planDetails';
import { get as _get } from 'lodash-es';
import { apolloClient } from '@/apollo';
import { getCountryByLocale } from '@/config/countries';
import { PERIOD_TYPES } from '@/config/payment';
import { LIMIT_PROBLEM_TYPES } from '@/config/limitProblemTypes';
import GET_BILLING from '@/graphql/GetBilling.gql';
import GET_ACCOUNT from '@/graphql/GetAccount.gql';
import GET_DECLINE_DETAIL from '@/graphql/GetDeclinedPaymentDetail.gql';
import GET_FLEXIPAY_DETAIL from '@/graphql/GetFlexiPayDetail.gql';
import GET_INVOICE_PDF from '@/graphql/GetInvoicePdf.gql';
import { separatePrice } from '@/util';
import { PlanListService } from '@/services/planListService';
import { CouponService } from '@/services/couponService';
import moment from 'moment';

const _clone = (v) => JSON.parse(JSON.stringify(v));

const defaultDeclineDetails = {
  declined: false,
  errorCode: null,
  errorMessage: null,
  lastOrderId: 0,
};

const defaultFlexiPayDetails = {
  flexiPayTotal: { total: '', currency: '' },
  usageLimitRatio: 0,
  nextLimitResetDate: '',
  allowedMaxUsageLimitRatio: 0,
  overrunMaximumTotal: 0,
};

export default {
  namespaced: true,
  state: {
    cancelled: false,
    billing: {
      address: '',
      billingName: '',
      city: '',
      country: '',
      countryCode: '',
      countryId: null,
      euVatNumber: '',
      firstName: '',
      lastName: '',
      postalCode: '',
      nonProfit: '',
    },
    datePaid: null,
    dateExpires: null,
    paymentMethod: 'none',
    paymentData: { active: true },
    paymentRecord: null,
    plans: [],
    selectedPlan: '',
    selectedPeriod: 1,
    declineDetails: defaultDeclineDetails,
    flexiPayDetails: defaultFlexiPayDetails,
    overrun_limit: 10000,
    hufToUsd: 250,
    couponService: null,
    planListService: null,
  },
  getters: {
    isPaymentEnabledForAccount: (_, __, ___, rootGetters) => {
      return !rootGetters.isSubUser;
    },
    isPaymentEnabledForUser: (_, getters, __, rootGetters) => {
      return (
        getters.isPaymentEnabledForAccount &&
        (rootGetters.isOwner || rootGetters.isAgencyImpersonate)
      );
    },
    isNonPayingPlan: (_, __, ___, rootGetters) => {
      return planDetails.isNonPayingPlan(rootGetters.currentPlan);
    },
    isFreePackage: (_, __, ___, rootGetters) => {
      return rootGetters.currentPlan === 'FREE';
    },
    isFreePackageBy: () => (plan) => {
      return plan.includes('FREE');
    },
    usedPageViews: (_, __, rootState) => {
      return rootState.account.limits.pageViews;
    },
    maxPageViews: (_, __, rootState) => {
      return rootState.account.limits.maxPageViews;
    },
    getRegion: (_, __, rootState) => {
      return rootState.account.login.region;
    },
    calculateCurrentOverRun: (_, getters) => {
      const overrunValue = getters.limitUsageInPercent;
      if (getters.isOverrunRunning) {
        const whole = (100 / overrunValue) * 100;
        const rest = 100 - whole;
        return {
          value: whole,
          rest,
        };
      }
      return { value: overrunValue, rest: null };
    },
    maxLimit: (_, __, rootState, rootGetters) => {
      return rootGetters.isPageViewBasedPackage
        ? rootState.account.limits.maxPageViews
        : rootState.account.limits.maxVisitor;
    },
    usedLimit: (_, __, rootState, rootGetters) => {
      return rootGetters.isPageViewBasedPackage
        ? rootState.account.limits.pageViews
        : rootState.account.limits.usedVisitor;
    },
    getNextPaymentDate: (state) => {
      const dateExpires = moment(state.dateExpires);
      const datePaid = moment(state.datePaid);
      const diff = dateExpires.diff(datePaid, 'days');

      if (diff > 14) {
        return dateExpires.subtract(14, 'days');
      }

      return datePaid;
    },
    hasFuturePackage: (_, __, rootState) => {
      return !!rootState.account.billing.futurePackage;
    },
    getFuturePackage: (_, __, rootState) => {
      return rootState.account.billing.futurePackage;
    },
    isMonthlyPeriod: (state) => {
      return state.selectedPeriod === 1;
    },
    isQuarterlyPeriod: (state) => {
      return state.selectedPeriod === 3;
    },
    isAnnualPeriod: (state) => {
      return state.selectedPeriod === 12;
    },
    getFlexiPayTotalInHuf: (state) => {
      const { flexiPayTotal } = state.flexiPayDetails;
      return flexiPayTotal.totalInHuf ?? 0;
    },
    isOverrunRunning: (state) => {
      const { usageLimitRatio } = state.flexiPayDetails;
      return usageLimitRatio > 1;
    },
    isOverrunDisabled: (state) => {
      const { usageLimitRatio, allowedMaxUsageLimitRatio } = state.flexiPayDetails;
      return allowedMaxUsageLimitRatio && usageLimitRatio > allowedMaxUsageLimitRatio;
    },
    overrunMaximumTotal(state) {
      const { overrunMaximumTotal } = state.flexiPayDetails;
      return overrunMaximumTotal || 0;
    },
    getOverrunLimit: (state) => {
      return state.overrun_limit;
    },
    getHufUsdRate: (state) => {
      return state.hufToUsd;
    },
    limitUsageInPercent: (state, getters, rootState) => {
      if (!rootState.account.limits.isOverrun) {
        return getters.maxLimit ? (getters.usedLimit / getters.maxLimit) * 100 : 0;
      }
      const { usageLimitRatio } = state.flexiPayDetails;
      return usageLimitRatio * 100;
    },
    getCurrentPeriodLanguageKey: (_, getters, ___, rootGetters) => {
      return getters.getPeriodLanguageKey(rootGetters.currentPeriod);
    },
    getPeriodLanguageKey: () => (periodInMonth) => {
      if (periodInMonth === 1) return PERIOD_TYPES.MONTHLY;
      if (periodInMonth === 12) return PERIOD_TYPES.ANNUAL;
      if (periodInMonth === 3) return PERIOD_TYPES.QUARTERLY;
    },
    hasCustomPay: (state) => {
      return Object.keys(state.paymentRecord || {}).length;
    },
    hasCoupon() {
      return Cookies.get('x-om-coupon') !== undefined;
    },
    isOverrun(_, __, rootState) {
      return _get(rootState, 'account.limits.isOverrun', false);
    },
    getPlans: (state) => state.plans,
    getPlainPlanName: () => (name) => {
      return name
        .toUpperCase()
        .replace('_', ' ')
        .replace(/([^\d]+)\d+/, '$1')
        .replace(/^(ENTERPRISE|MASTER|MANAGED_REFERRAL)(.*)+/, '$1');
    },
    getBilling(_, __, rootState) {
      return _get(rootState, 'account.billing', {});
    },
    isInactive(state, getters, _, rootGetters) {
      return (
        state.cancelled ||
        (getters.isBraintreePayment && state.paymentData.active === false) ||
        (getters.isShopifyPayment && state.paymentData.active === false) ||
        (getters.isBankTransfer && rootGetters.isAccountExpired)
      );
    },
    hasActiveDefaultPaymentMethod(state, getters) {
      if (!getters.hasPaymentMethod) return false;

      if (getters.isBankTransfer) return true;

      const { active, paymentMethods } = state.paymentData;

      if (getters.isBraintreePayment) {
        if (!active || !paymentMethods) return false;
        const defaultPaymentMethod = paymentMethods.find((method) => method.default);
        return !!defaultPaymentMethod;
      }

      if (getters.isShopifyPayment) {
        return !!active;
      }
      return false;
    },
    hasPaymentMethod(state) {
      return ['none', '', null].includes(state.paymentMethod) === false;
    },
    getPaymentMethod(state) {
      return state.paymentMethod;
    },
    isBraintreePayment(state) {
      return state.paymentMethod?.indexOf?.('braintree') === 0;
    },
    isPaypal(state) {
      return state.paymentMethod?.indexOf?.('paypal') !== -1;
    },
    isShopifyPayment(state) {
      return state.paymentMethod === 'shopify';
    },
    isBankTransfer(state) {
      return state.paymentMethod === 'bank_transfer';
    },
    isEnterpriseOrMaster(_, getters, rootState) {
      const currentPlan = rootState.currentPlan.toLowerCase();
      return getters.isEnterpriseOrMasterPlan(currentPlan);
    },
    getPlanDetail: (state) => (planName) => {
      return state.plans.find((plan) => {
        return plan.name === planName;
      });
    },
    isUpgradeablePlan: (state) => {
      const notUpgradeablePlans = ['diamond', 'premium', 'master', 'enterprise'];
      const { selectedPlan } = state;
      return !notUpgradeablePlans.includes(selectedPlan.split('_')[0]);
    },
    isEnterpriseOrMasterPlan() {
      return (planName) =>
        planName.indexOf('enterprise') === 0 ||
        planName.indexOf('master_') === 0 ||
        planName === 'demo';
    },
    isEnterpriseOrMasterCurrentPlan(_, __, rootState) {
      const planName = rootState.currentPlan.toLowerCase();
      return (
        planName.indexOf('enterprise') === 0 ||
        planName.indexOf('master_') === 0 ||
        planName === 'demo'
      );
    },
    isAnnualToMonthlyPlanChange(_, getters, __, rootGetters) {
      if (rootGetters.currentPeriod === 12 && getters.isMonthlyPeriod) {
        return true;
      }
      return false;
    },
    isDowngrade:
      (state, getters, rootState, rootGetters) =>
      (planName, defaultPlans = []) => {
        planName = planName.toUpperCase();
        const isExpired = !rootGetters.isPaying || getters.isPlanExpired;

        if (
          isExpired ||
          getters.isEnterpriseOrMasterPlan(planName) ||
          rootState.partnerTypeService.isResellerPartnerType() ||
          rootState.partnerTypeService.isCustom()
        ) {
          return false;
        }

        const currentPlan = rootGetters.currentPlan;
        const plans = rootGetters.isPageViewBasedPackage
          ? defaultPlans
          : Array.from(state.plans).sort((a, b) => {
              const { priceNumber: aPrice } = separatePrice(a.price.monthly);
              const { priceNumber: bPrice } = separatePrice(b.price.monthly);

              return aPrice > bPrice ? 1 : -1;
            });
        const minimumPlanIndex = plans.findIndex((plan) => {
          return plan.name === planName;
        });
        const desiredPlanIndex = plans.findIndex((plan) => {
          return plan.name === currentPlan;
        });

        return minimumPlanIndex < desiredPlanIndex;
      },
    hasFlexiPayPlanUpdateBlocked(state, getters, _, rootGetters) {
      if (!getters.isShopifyPayment) return false;
      if (rootGetters.getDowngradeInformation) return true;
      if (state.couponService.hasValidPendingCoupon()) return true;
      if (state.couponService.hasValidNextBillingCoupon()) return true;
      return false;
    },
    needsOverchargeUpgrade(state, getters, rootState, rootGetters) {
      if (!rootState.account.limits.isOverrun || !getters.isShopifyPayment) {
        return false;
      }
      if (rootGetters.currentPeriod === 12) {
        return false;
      }
      if (getters.hasFlexiPayPlanUpdateBlocked) return false;

      const hasUsagePlan = state.paymentData?.hasUsagePlan || false;
      return !hasUsagePlan;
    },
    needsFlexiPayMaximumTotalUpdate(state, getters, rootState, rootGetters) {
      if (!rootState.account.limits.isOverrun || !getters.isShopifyPayment) {
        return false;
      }
      if (rootGetters.currentPeriod === 12) {
        return false;
      }
      if (getters.hasFlexiPayPlanUpdateBlocked) return false;

      const hasValidUsagePlanMaximumTotal =
        state.paymentData?.hasValidUsagePlanMaximumTotal || false;

      return !hasValidUsagePlanMaximumTotal;
    },
    needsShopifyPlanUpdateWithNewPrice(state) {
      if (state.paymentMethod !== 'shopify') return false;
      return state.paymentData?.needsShopifyPlanUpdateWithNewPrice || false;
    },
    getDatePaidFromExpires(_, getters) {
      const dateExpires = moment(getters.getDateExpires);
      return dateExpires.subtract(14, 'days');
    },
    getDateExpires(state) {
      return state.dateExpires;
    },
    getRemainingDaysToAccountExpiration(_, getters) {
      const dateExpires = moment(getters.getDateExpires);
      return dateExpires.diff(moment(), 'days') + 1;
    },
    isPlanExpired(_, getters) {
      const remainingDays = getters.getRemainingDaysToAccountExpiration;
      return remainingDays < 14;
    },
    isPlanSameAsCurrent(state, _, rootState) {
      return (planName) => {
        return planName.toUpperCase() === rootState.currentPlan.toUpperCase();
      };
    },
    getSelectedPlan(state) {
      return {
        name: state.selectedPlan,
        period: state.selectedPeriod,
      };
    },
    getDeclinedPaymentDetails(state) {
      const details = _get(state, 'declineDetails', null);
      return details?.paymentMethod ? details : null;
    },
    hasBraintreePaymentProblem(_, getters) {
      if (getters.isBraintreePayment === false) {
        return false;
      }

      const details = getters.getDeclinedPaymentDetails;
      return !!details?.declined;
    },
    getBraintreePaymentErrorCode(_, getters) {
      if (getters.isBraintreePayment === false) {
        return null;
      }

      const details = getters.getDeclinedPaymentDetails;
      if (!details?.declined || !details?.errorCode) return null;

      return details.errorCode;
    },
    hasBankTransferPaymentProblem(_, getters) {
      if (getters.isBankTransfer === false || !getters.getLastOrderId) {
        return false;
      }

      const details = getters.getDeclinedPaymentDetails;
      return !!details?.declined;
    },
    hasShopifyPaymentProblem(_, getters) {
      if (getters.isShopifyPayment === false) {
        return false;
      }

      const details = getters.getDeclinedPaymentDetails;
      return !!details?.declined;
    },
    getLastOrderId(state) {
      return _get(state, 'declineDetails.lastOrderId', null);
    },
    hasGracePeriodForPriceChange(_, __, rootState) {
      return rootState.account.billing.gracePeriod?.reasonType === 'different-price';
    },
    getGracePeriodStartDate(_, __, rootState) {
      return moment(rootState.account.billing.gracePeriod?.startDate);
    },
    getGracePeriodDeadline(_, getters) {
      return moment(getters.getGracePeriodStartDate).add(14, 'days');
    },
    isGracePeriodExpired(_, getters) {
      return getters.getGracePeriodDeadline <= moment();
    },
    getLimitProblemType(_, getters) {
      if (getters.isOverrun && getters.isOverrunDisabled) {
        return LIMIT_PROBLEM_TYPES.FLEXIPAY_OVER_CAPPED_AMOUNT;
      }
      if (!getters.isOverrun && getters.limitUsageInPercent >= 100) {
        return getters.isUpgradeablePlan
          ? LIMIT_PROBLEM_TYPES.NON_FLEXIPAY_OVER_100P
          : LIMIT_PROBLEM_TYPES.NON_FLEXIPAY_OVER_100P_WITHOUT_UPGRADE;
      }
      if (getters.isNonPayingPlan && getters.limitUsageInPercent > 50) {
        return LIMIT_PROBLEM_TYPES.NON_PAYING_OVER_50P;
      }
      return null;
    },
  },
  actions: {
    async loadPlans({ state, commit, getters, rootGetters, rootState }) {
      if (getters.isPaymentEnabledForAccount === false) {
        return;
      }

      if (rootState.account?.databaseId) {
        state.couponService.setAccountId(rootState.account.databaseId);
        state.couponService.setCurrentPlanName(rootState.currentPlan);
        if (getters.isShopifyPayment) state.couponService.setAsShopify();
        await state.couponService.init();
      }

      const planListService = state.planListService;
      planListService.setCouponService(state.couponService);
      planListService.setRegion(rootGetters.getRegion);
      planListService.setCurrentPlanName(rootState.currentPlan);
      planListService.setCurrentPlanPeriod(rootState.currentPeriod);
      if (getters.isInactive) planListService.setAsInactive();
      if (getters.isShopifyPayment) planListService.setAsShopifyPay(getters.isShopifyPayment);
      const plans = await planListService.getPlanList();
      const period = planListService.getSuggestedPeriod();

      commit('setPlans', _clone(plans));
      commit('setPeriod', period);
    },

    async loadBilling({ state, commit, getters, rootGetters }, payload) {
      if (getters.isPaymentEnabledForUser === false) return;

      const planValidation = payload?.planValidation ?? false;

      try {
        const {
          data: { getBilling },
        } = await apolloClient.query({
          query: GET_BILLING,
          variables: { planValidation },
        });
        const countries = getCountryByLocale(rootGetters.getLocale);
        let countryId = getBilling.billing.countryId;
        if (!countryId) {
          countryId = rootGetters.getRegion === 'Hungary' ? 97 : 223; // 97 = Hungary, 223 = USA
        }

        const country = countries.find((c) => c.value === countryId);

        getBilling.billing.countryCode = country.code;
        getBilling.billing.country = country.text;

        commit('setBilling', getBilling.billing);
        commit('setPaymentMethod', _get(getBilling, 'paymentMethod.type', 'none'));
        commit(
          'setPaymentData',
          _get(getBilling, 'paymentMethod.data.paymentData', { active: true }),
        );

        if (state.paymentMethod === 'shopify') {
          commit('setPaymentRecord', _get(getBilling, 'paymentMethod.data.paymentRecord'));
        }
      } catch (e) {
        console.warn(e.message);
      }
    },

    async fetchFlexiPayDetail({ commit, getters }) {
      if (getters.isPaymentEnabledForAccount === false) {
        commit('setFlexiPayDetails', defaultFlexiPayDetails);
        return;
      }
      try {
        const {
          data: { flexiPayDetail },
        } = await apolloClient.query({
          query: GET_FLEXIPAY_DETAIL,
        });

        if (flexiPayDetail && flexiPayDetail.success) {
          commit('setFlexiPayDetails', flexiPayDetail.details);
          return flexiPayDetail.details;
        }
      } catch (e) {
        commit('setFlexiPayDetails', defaultFlexiPayDetails);
      }

      return null;
    },

    async loadAccount({ state, commit, getters }) {
      const {
        data: { account },
      } = await apolloClient.query({
        query: GET_ACCOUNT,
      });

      const { billing } = account;

      if (getters.isPaymentEnabledForAccount) {
        state.dateExpires = billing.dateExpires;
        state.datePaid = billing.datePaid;
        const currentPlan = billing.package?.toLowerCase() ?? '';

        state.cancelled = !!billing.cancelledAt;

        commit('setPlan', currentPlan);
        commit('setPeriod', billing.period);

        commit('setCurrentPlan', currentPlan, { root: true });
        commit('setCurrentPeriod', billing.period, { root: true });
      }
    },

    async loadDeclinedPaymentDetail({ commit, getters }) {
      if (getters.isPaymentEnabledForAccount === false) {
        commit('setDeclineDetails', defaultDeclineDetails);
        return;
      }

      try {
        const {
          data: { declinedPaymentDetail },
        } = await apolloClient.query({
          query: GET_DECLINE_DETAIL,
        });

        if (declinedPaymentDetail && declinedPaymentDetail.success) {
          commit('setPaymentMethod', declinedPaymentDetail.details.paymentMethod);
          commit('setDeclineDetails', declinedPaymentDetail.details);
        }
      } catch (e) {
        commit('setDeclineDetails', defaultDeclineDetails);
      }
    },
    async downloadProforma({ dispatch }, orderId) {
      return dispatch('downloadInvoice', { orderId, type: 'proforma' });
    },

    async downloadInvoice(_, { orderId, type }) {
      const {
        data: {
          getInvoicePdf: { invoicePdf },
        },
      } = await apolloClient.query({
        query: GET_INVOICE_PDF,
        variables: {
          orderId,
          type,
        },
      });

      const linkSource = `data:application/pdf;base64,${invoicePdf}`;
      const downloadLink = document.createElement('a');
      const fileName = `OM-${orderId}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    async initCouponService({ commit }) {
      const couponService = new CouponService(apolloClient);
      commit('setCouponService', couponService);
    },

    async initPlanListService({ commit }) {
      const planListService = new PlanListService(apolloClient);
      commit('setPlanListService', planListService);
    },
  },
  mutations: {
    setCancelled(state, cancelled) {
      state.cancelled = cancelled;
    },
    setBilling(state, billing) {
      state.billing = billing;
    },
    setPaymentMethod(state, paymentMethod) {
      state.paymentMethod = paymentMethod;
    },
    setPaymentData(state, paymentData) {
      state.paymentData = paymentData;
    },
    setPaymentRecord(state, paymentRecord) {
      state.paymentRecord = paymentRecord;
    },
    setPeriod(state, period) {
      state.selectedPeriod = parseInt(period, 10);
    },
    setPlan(state, plan) {
      state.selectedPlan = plan;
    },
    setPlans(state, plans) {
      state.plans = plans.map((plan) => {
        return {
          ...plan,
          name: plan.name.toUpperCase(),
        };
      });
    },
    setDeclineDetails(state, details) {
      state.declineDetails = details;
    },
    setFlexiPayDetails(state, flexiPayDetails) {
      state.flexiPayDetails = flexiPayDetails;
    },
    setCouponService(state, service) {
      state.couponService = service;
    },
    setPlanListService(state, service) {
      state.planListService = service;
    },
  },
};
