import * as Cookies from 'js-cookie';
import moment from 'moment';

import { CouponValidationForPlan } from '@om/payment/src/coupon/CouponValidationForPlan';
import { PriceCalculationByCoupon } from '@om/payment/src/coupon/PriceCalculationByCoupon';

import VALIDATE_COUPON from '@/graphql/ValidateCoupon.gql';
import GET_VALIDATED_COUPON_BY_CODE from '@/graphql/GetValidatedCouponByCode.gql';
import GET_VALID_ACTIVATED_COUPON from '@/graphql/GetValidActivatedCoupon.gql';
import ADD_AFFILIATE_BY_COUPON from '@/graphql/AddAffiliateRelationshipByCouponCode.gql';

const NOTIFICATION_TYPES = {
  INELIGIBLE_COUPON: 'activation-ineligible-coupon',
};

const COOKIE_KEY_PREFIX = 'x-om-coupon-';

class CouponService {
  constructor(apollo) {
    this.apollo = apollo;
    this.accountId = null;
    this.currentPlanName = null;
    this.isShopify = false;

    this.coupon = null;
    this.activatedCoupon = null;
    this.pendingCoupon = null; // { activatedCoupon, coupon }
    this.nextBillingCoupon = null; // { activatedCoupon, coupon }
    this.notificationType = null;
    this.hasStartedActivation = false;
  }

  setAccountId(id) {
    this.accountId = id;
  }

  setCurrentPlanName(name) {
    this.currentPlanName = name.toUpperCase();
  }

  setAsShopify() {
    this.isShopify = true;
  }

  get cookieKey() {
    return `${COOKIE_KEY_PREFIX}${this.accountId}`;
  }

  async init() {
    const { activatedCoupon, coupon, pendingCoupon, nextBillingCoupon } =
      await this._loadActivatedCoupon();
    this.activatedCoupon = activatedCoupon;
    this.coupon = coupon;
    this.pendingCoupon = pendingCoupon;
    this.nextBillingCoupon = nextBillingCoupon;

    if (this.coupon || this.pendingCoupon || this.nextBillingCoupon) return;

    const couponCode = Cookies.get(this.cookieKey);
    if (couponCode && this.isEnabledCouponActivation()) {
      this.coupon = await this._loadCoupon();
      this.hasStartedActivation = true;
      this.notificationType = null;
    }
  }

  reload() {
    return this.init();
  }

  afterOrderActions() {
    this._removeCouponCookie();
    this.hasStartedActivation = false;
  }

  hasStartedCouponActivation() {
    return !!this.hasStartedActivation;
  }

  resetStartedCouponActivation() {
    this._removeCouponCookie();
    this.hasStartedActivation = false;
  }

  hasValidCoupon() {
    return !!this.coupon;
  }

  hasValidActivatedCoupon() {
    if (!this.activatedCoupon) return false;
    if (!this.activatedCoupon?.dateStart) return false;
    return true;
  }

  hasValidCouponForPlan(planName, periodInMonth) {
    if (!this.hasValidCoupon()) return false;
    const plan = { name: planName, period: periodInMonth };
    const couponValidationForPlan = new CouponValidationForPlan(plan, this.coupon);
    return couponValidationForPlan.isValid();
  }

  hasValidNextBillingCoupon() {
    return !!this.nextBillingCoupon;
  }

  hasValidPendingCoupon() {
    return !!this.pendingCoupon;
  }

  getDiscountedPriceForPlan(planName, periodInMonth, originalPrice, currency) {
    const plan = {
      name: planName,
      period: periodInMonth,
      originalPrice,
      originalFullprice: originalPrice,
      currency,
    };
    const priceCalculationByCoupon = new PriceCalculationByCoupon(
      plan,
      this.coupon,
      this.activatedCoupon,
    );
    return priceCalculationByCoupon.getDiscountedPrice();
  }

  getNextBillingDiscountedPriceForPlan(planName, periodInMonth, originalPrice, currency) {
    const plan = {
      name: planName,
      period: periodInMonth,
      originalPrice,
      originalFullprice: originalPrice,
      currency,
    };
    let coupon = null;
    let activatedCoupon = null;
    if (this.hasValidPendingCoupon()) {
      coupon = this.pendingCoupon.coupon;
      activatedCoupon = this.pendingCoupon.activatedCoupon;
    } else if (this.hasValidNextBillingCoupon()) {
      coupon = this.nextBillingCoupon.coupon;
      activatedCoupon = this.nextBillingCoupon.activatedCoupon;
    }
    const priceCalculationByCoupon = new PriceCalculationByCoupon(plan, coupon, activatedCoupon);
    return priceCalculationByCoupon.getDiscountedPrice();
  }

  calculateDiscountForUpgradePrice(
    planName,
    periodInMonth,
    originalFullprice,
    upgradePrice,
    currency,
  ) {
    const plan = {
      name: planName,
      period: periodInMonth,
      originalFullprice,
      originalPrice: upgradePrice,
      currency,
    };
    const priceCalculationByCoupon = new PriceCalculationByCoupon(
      plan,
      this.coupon,
      this.activatedCoupon,
    );
    return priceCalculationByCoupon.getDiscountAmount();
  }

  getCouponCode() {
    return this.coupon?.code ?? null;
  }

  getCouponDescription(languageKey = 'hu') {
    if (this.hasValidNextBillingCoupon()) {
      if (!this.nextBillingCoupon?.coupon?.description) return '';
      return this.nextBillingCoupon.coupon?.description[languageKey] ?? '';
    }
    if (!this.coupon?.description) return '';
    return this.coupon.description[languageKey] ?? '';
  }

  getCouponValidityPeriod() {
    if (this.hasValidNextBillingCoupon()) {
      if (!this.nextBillingCoupon?.activatedCoupon?.dateEnd) return null;
      return {
        start: moment.utc(this.nextBillingCoupon?.activatedCoupon?.dateStart).toDate(),
        end: moment.utc(this.nextBillingCoupon?.activatedCoupon?.dateEnd).toDate(),
      };
    }
    if (this.hasValidActivatedCoupon() === false || !this.activatedCoupon?.dateEnd) return null;
    return {
      start: moment.utc(this.activatedCoupon.dateStart).toDate(),
      end: moment.utc(this.activatedCoupon.dateEnd).toDate(),
    };
  }

  isEnabledCouponActivation() {
    return true;
  }

  async activateCoupon(couponCode) {
    if (!couponCode) return false;

    const isValid = await this._isValidActivation(couponCode);
    if (!isValid) {
      this.notificationType = NOTIFICATION_TYPES.INELIGIBLE_COUPON;
      return false;
    }
    this.notificationType = null;
    Cookies.set(this.cookieKey, couponCode);

    await this._addAffiliateRelationship(couponCode);

    return true;
  }

  getNotificationType() {
    return this.notificationType ?? null;
  }

  async _isValidActivation(couponCode) {
    let data;
    try {
      const {
        data: { validateCoupon },
      } = await this.apollo.query({
        query: VALIDATE_COUPON,
        variables: {
          couponCode,
        },
      });
      data = validateCoupon;
    } catch (e) {
      console.error(e.message);
      return false;
    }

    if (!!data?.success && !!data?.details) {
      return data.details.isValid || false;
    }

    console.error('Unknown error during coupon validation!');
    return false;
  }

  async _loadCoupon() {
    const couponCode = Cookies.get(this.cookieKey);
    if (!couponCode) {
      return null;
    }

    let data;
    try {
      const {
        data: { getValidatedCouponByCode },
      } = await this.apollo.query({
        query: GET_VALIDATED_COUPON_BY_CODE,
        variables: {
          couponCode,
        },
      });
      data = getValidatedCouponByCode;
    } catch (e) {
      console.error(e.message);
      return null;
    }

    if (!!data?.success && !!data?.details?.isValid && !!data?.details?.coupon) {
      return data.details.coupon || null;
    }

    return null;
  }

  async _loadActivatedCoupon() {
    let data;
    try {
      const {
        data: { getValidActivatedCoupon },
      } = await this.apollo.query({
        query: GET_VALID_ACTIVATED_COUPON,
      });
      data = getValidActivatedCoupon;
    } catch (e) {
      console.error(e.message);
      return null;
    }

    const result = {
      activatedCoupon: null,
      coupon: null,
      pendingCoupon: null,
      nextBillingCoupon: null,
    };

    if (
      !data?.success ||
      !data?.details?.coupon ||
      !data?.details?.activatedCoupon ||
      !data?.details?.isValid
    ) {
      return result;
    }

    if (data?.details?.isPendingCoupon) {
      if (!data?.details?.isValidForPlan) {
        return false;
      }
      result.pendingCoupon = {
        activatedCoupon: data.details.activatedCoupon,
        coupon: data.details.coupon,
      };
      return result;
    }

    if (data?.details?.isNextBillingCoupon) {
      if (!data?.details?.isValidForPlan && !this.isShopify) {
        return false;
      }
      result.nextBillingCoupon = {
        activatedCoupon: data.details.activatedCoupon,
        coupon: data.details.coupon,
      };
      return result;
    }

    if (!data?.details?.isValidForPlan && !this.isShopify) {
      return false;
    }
    result.activatedCoupon = data.details.activatedCoupon;
    result.coupon = data.details.coupon;

    return result;
  }

  _removeCouponCookie() {
    Cookies.remove(this.cookieKey);
  }

  async _addAffiliateRelationship(couponCode) {
    let data;
    try {
      const {
        data: { addAffiliateRelationshipByCouponCode },
      } = await this.apollo.query({
        query: ADD_AFFILIATE_BY_COUPON,
        variables: {
          couponCode,
        },
      });
      data = addAffiliateRelationshipByCouponCode;
    } catch (e) {
      console.error(e.message);
      return false;
    }

    if (!!data?.success && !!data?.details) {
      return data.details.added || false;
    }

    console.error('Unknown error during adding affiliate partnership!');
    return false;
  }
}

export { CouponService };
