import OmComponent from './OmComponent';
import {
  DesktopBackground,
  DesktopBorder,
  DesktopShadow,
  OverlayBackground,
  OldTeaserBackground,
  GlobalStyle,
  MobileBackground,
  MobileBorder,
  MobileShadow,
} from '../coloring';

export default class OmGlobal extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'desktop.background.color':
      case 'selectedPage.desktop.background.color':
      case 'selectedPage.desktop.background.color2':
        return this.createColorInstance(DesktopBackground);

      case 'mobile.background.color':
      case 'selectedPage.mobile.background.color':
      case 'selectedPage.mobile.background.color2':
        return this.createColorInstance(MobileBackground);

      case 'desktop.border.color':
      case 'selectedPage.border.color':
        return this.createColorInstance(DesktopBorder);

      case 'mobile.border.color':
        return this.createColorInstance(MobileBorder);

      case 'desktop.shadow.color':
      case 'selectedPage.shadow.color':
        return this.createColorInstance(DesktopShadow);

      case 'mobile.shadow.color':
        return this.createColorInstance(MobileShadow);

      case 'globalStyle.overlay.background.color':
      case 'globalStyle.overlay.background.color2':
        return this.createColorInstance(OverlayBackground);

      case 'globalStyle.closeButton.desktop.background.color':
      case 'globalStyle.closeButton.desktop.background.color2':
        return this.createColorInstance(GlobalStyle).setBaseProperty(
          'closeButton.desktop.background',
        );

      case 'globalStyle.closeButton.mobile.background.color':
      case 'globalStyle.closeButton.mobile.background.color2':
        return this.createColorInstance(GlobalStyle).setBaseProperty(
          'closeButton.mobile.background',
        );

      case 'globalStyle.teaserCloseButton.desktop.background.color':
      case 'globalStyle.teaserCloseButton.desktop.background.color2':
        return this.createColorInstance(GlobalStyle).setBaseProperty(
          'teaserCloseButton.desktop.background',
        );

      case 'globalStyle.teaserCloseButton.mobile.background.color':
      case 'globalStyle.teaserCloseButton.mobile.background.color2':
        return this.createColorInstance(GlobalStyle).setBaseProperty(
          'teaserCloseButton.mobile.background',
        );

      case 'globalStyle.closeButton.desktop.color':
      case 'globalStyle.closeButton.desktop.color2':
        return this.createColorInstance(GlobalStyle).setBaseProperty('closeButton.desktop');

      case 'globalStyle.closeButton.mobile.color':
      case 'globalStyle.closeButton.mobile.color2':
        return this.createColorInstance(GlobalStyle).setBaseProperty('closeButton.mobile');

      case 'globalStyle.teaserCloseButton.desktop.color':
      case 'globalStyle.teaserCloseButton.desktop.color2':
        return this.createColorInstance(GlobalStyle).setBaseProperty('teaserCloseButton.desktop');

      case 'globalStyle.teaserCloseButton.mobile.color':
      case 'globalStyle.teaserCloseButton.mobile.color2':
        return this.createColorInstance(GlobalStyle).setBaseProperty('teaserCloseButton.mobile');

      // OLD teaser

      case 'globalStyle.tab.background.color':
      case 'globalStyle.tab.background.color2':
        return this.createColorInstance(OldTeaserBackground);

      case 'globalStyle.tab.border.color':
        return this.createColorInstance(GlobalStyle).setBaseProperty('tab.border');

      case 'globalStyle.tab.shadow.color':
        return this.createColorInstance(GlobalStyle).setBaseProperty('tab.shadow');

      default:
        throw new Error(`OmGlobal: unknown property ${property}`);
    }
  }
}
